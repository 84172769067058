import styled from "styled-components";

export const AboutUsSectionContainer = styled("div")`
  width: 100%;
  margin-bottom: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
  padding: 3rem 0;

  .whoWeAre {
    color: #ffbb3e;
    font-weight: thin;
  }
  .contentWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .textSection {
      width: 50%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: column;
      .h1Wrappper {
        display: flex;
        justify-content: flex-start;
        align-items: baseline;
        h1 {
          font-size: 2.3rem;
          color: #ffffff;
          padding: 0;
          margin: 0.5rem 0;
        }
      }
      .paragraph {
        font-size: 1.12rem;
        color: white;
        margin: 0.9rem 0;
        font-weight: thin;
        word-spacing: 0.3rem;
      }

      .videoWrapper {
        width: 100%;
        height: 60vh;
        border-radius: 0.5rem;
        margin-top: 1.3rem;

        p {
          color: white;
          font-size: 2rem;
        }
      }
    }
    .imageSection {
      max-width: 45%;
      margin-top: 3rem;
      height: 70%;
      display: flex;

      justify-content: center;
      align-items: flex-start;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  @media screen and (max-width: 1000px) {
    align-items: center;
    padding: 3rem 0;
    margin-bottom: 20vh;

    .contentWrapper {
      flex-direction: column;
      align-items: center;
      margin-bottom: 4rem;

      .textSection {
        width: 100%;
        align-items: center;
        .h1Wrappper {
          justify-content: center;
          h1 {
            font-size: 1.5rem;
          }
        }
        .paragraph {
          font-size: 1rem;
          margin: 0.7rem 0;
          word-spacing: 0.2rem;
          text-align: center;
        }
      }
      .imageSection {
        max-width: 80%;
        height: 100%;
        align-items: center;
      }
    }
  }
  @media screen and (max-width: 320px) {
    .contentWrapper {
      .textSection {
        .h1Wrappper {
          h1 {
            font-size: 1rem;
          }
        }
        .paragraph {
          font-size: 0.9rem;
          margin: 0.5rem 0;
        }
      }
    }
  }
`;

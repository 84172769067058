import React from "react";
import { OurImpactContainer } from "./ourImpact.styles";
import YellowCircle from "../../../components/yellowCircle/YellowCircle";
import UpwardCounter from "../../../components/upwardCounter/upwardCounter.component";

const OurImpact = () => {
  const yellowCircle = [
    { key: 1 },
    { key: 2 },
    { key: 3 },
    { key: 4 },
    { key: 5 },
    { key: 6 },
    { key: 7 },
  ];

  const analytics = [
    {
      analyticTitle: "Registered Members",
      key: 0,
      finalNumber: 31400,
      beforeSign: false,
      afterSign: true,
    },
    {
      analyticTitle: "Cash Deposited",
      key: 1,
      finalNumber: 1081872,
      beforeSign: true,
      afterSign: true,
    },
    {
      analyticTitle: "Profits Made",
      key: 2,
      finalNumber: 707125,
      beforeSign: true,
      afterSign: true,
    },
  ];
  return (
    <OurImpactContainer>
      <div className="yellowCircles">
        {yellowCircle.map((circle) => (
          <YellowCircle size="0.7rem" marginRight="0.4rem" key={circle.key} />
        ))}
      </div>
      <div className="heading">
        <h3>Our Impact</h3>
        <YellowCircle size="0.4rem" marginRight="0.2rem" />
      </div>
      <p>Community, Profits, Payouts</p>
      <div className="analyticsWrapper">
        {analytics.map(({finalNumber,beforeSign,afterSign,key,analyticTitle}) => (
          <div className="analyticContainer" key={key}>
            <UpwardCounter
              fontSize="3.6rem"
              beforeSign={beforeSign}
              afterSign={afterSign}
              finalNumber={finalNumber}
            />
            <div className="analytics">
              <p>{analyticTitle}</p>
              <div className="bottomLine"></div>
            </div>
          </div>
        ))}
      </div>
    </OurImpactContainer>
  );
};

export default OurImpact;

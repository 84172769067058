import styled from "styled-components";
import Footer from "../../../assets/footerBackground1.gif"

export const FooterContainer = styled.footer`
  background: radial-gradient(rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.95),rgba(0,0,0,0.9)),
    url(${Footer});
  background-size: cover;
  background-repeat: round;
  background-position:0 -100px;
  padding: 1rem 0 1rem 0;
  padding-bottom: 20vh;
  div {
    max-width: 600px;
    margin: 2rem auto 0;
  }
  img {
    width: 120px;
    margin: 0 auto;
    display: block;
  }
  p {
    text-align: center;
    color: #c3c9d4;
    font-size: 0.9rem;
    margin: 3rem 0;
  }
  p:nth-child(4) {
    margin: 4rem 0 0.3rem 0;
  }
  p:nth-child(5) {
    margin: 0;
  }

  @media screen and (min-width: 600px) {
    p {
      font-size: 1.2rem;
      &.bottom {
        font-size: 1rem;
      }
    }
  }
  @media screen and (min-width: 800px) {
    img {
      width: 200px;
    }
  }
`;

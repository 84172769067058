import React from "react";
import { WhatInvestorSayContainer } from "./whatInvestorSay.styles";

const WhatInvestorSay = () => {
  return (
    <WhatInvestorSayContainer>
      <h1>What Investors Say</h1>
      {/* eslint-disable-next-line jsx-a11y/no-distracting-elements */}
      <marquee direction="up" height="auto">
        <div className="content">
          <p>
            Stripe Option is the best investment platform I have used. Fast
            payout!!
          </p>
          <h2>Xana</h2>
          <p>
            Marketer <br />I am impressed about their services. Their customer
            services are always here to help. Thanks to Stripe Option.
          </p>

          <h2>Samanta</h2>
          <p>
            South Africa
            <br />
            Stripe Option is one of the leading trading firm with great business
            integrity , excellent customer oriented service and great user
            interface strategy.
          </p>

          <h2>William Smith jr</h2>
          <p>
            Manchester , United Kingdom
            <br />
            Trading just got better with Stripe Option trading team. it’s easy ,
            it’s convenient and safe . I literally earn while i was asleep .
          </p>

          <h2>Robert Schmidt</h2>
          <p>
            Paris , France
            <br />I received profits update every time I earn from my account
            manager trades . I’m kicking myself for not putting in more capital
            into her best strategy used in earning on my behalf . After this I
            would love to invest more on Stripe Option.
          </p>

          <h2>Sarah Nguyen</h2>
          <p>
            Melbourne, Australia
            <br />
            Wow wow wow this is the best trading site anyone can get starting
            with . Easy withdrawals directly into my chase bank and all I did
            was send back my account manager 10% to her . All thanks to Lisa
            Hitherman for helping me with this opportunity.
          </p>

          <h2>Hannah Gilliland</h2>
          <p>Detroit, Michigan USA</p>
        </div>
      </marquee>
    </WhatInvestorSayContainer>
  );
};

export default WhatInvestorSay;

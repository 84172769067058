import styled from "styled-components";

export const ContactUsContainer = styled("div")`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 40vh;
  h1 {
    color: white;
    font-size: 4.2rem;
    font-weight: thin;
    margin: 0;
    padding: 0;
    text-align: center;
  }
  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    h2 {
      margin: 1.5rem 0 0 0;
      font-size: 1.7rem;
      color: #ffffff;
    }
    p {
      font-size: 1.2rem;
      margin: 0.3rem 0;
      color: #ffffff;
    }
  }
  .content:last-child {
    p {
      color: #b8bec8;
    }
  }
  @media screen and (max-width: 600px) {
    justify-content: flex-start;
    align-items: center;

    h1 {
      font-size: 3rem;
    }
    .content {
      align-items: center;

      h2 {
        margin: 1.3rem 0 0 0;
        font-size: 1.5rem;
      }
      p {
        text-align: center;
      }
    }
  }
`;
import React from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { logUserOut } from "./../../redux/user/user.actions";

import DrawerButton from "./drawer-button.component";
import { DrawerContainer } from "./drawer.styles";

const Drawer = ({ image, show, logUserOut, userToken }) => {
  const history = useHistory();
  if (!userToken) {
    history.push("/login");
  }
  const handleLogout = () => {
    setTimeout(() => logUserOut(), 500);
    history.push("/login");
  };
  return (
    <DrawerContainer className={`${show ? "display" : "hidden"}`}>
      {/* <div className="profile">
        <img className="profile" src={image} alt="Profile" />
        <p>Dro Taiwo</p>
        <img className="verified" src={verified} alt="Verified User" />
      </div> */}
      <div className="button-container">
        <DrawerButton
          onClick={() => history.push("/user/dashboard")}
          text="Dashboard"
          icon={
            <svg
              version="1.1"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 512 512"
              style={{ enableBackground: "new 0 0 512 512" }}
            >
              <g>
                <g>
                  <path d="M286,376h-60c-8.276,0-15,6.724-15,15s6.724,15,15,15h60c8.276,0,15-6.724,15-15S294.276,376,286,376z" />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M256,191.314c-8.511,40.237-14.955,73.393-15,79.691c0,8.266,6.729,14.995,15,14.995c8.27,0,14.998-6.727,15-14.996
			C270.955,264.706,264.511,231.55,256,191.314z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M256,16C115.39,16,0,130.39,0,271c0,92.637,51.361,178.125,132.44,223.11c2.227,1.245,4.731,1.89,7.28,1.89h232.559
			c2.549,0,5.054-0.645,7.28-1.89C460.639,449.125,512,363.637,512,271C512,130.39,396.61,16,256,16z M286,436h-60
			c-24.814,0-45-20.186-45-45c0-24.814,20.186-45,45-45h60c24.814,0,45,20.186,45,45C331,415.814,310.814,436,286,436z M436,286h-60
			c-8.291,0-15-6.709-15-15c0-8.291,6.709-15,15-15h44.326c-3.1-34.244-17.095-65.153-37.95-90.165l-30.912,30.912
			c-5.86,5.859-15.351,5.859-21.211,0c-5.859-5.859-5.859-15.352,0-21.211l30.905-30.905c-24.957-20.803-56.006-34.063-90.157-37.2
			v11.904c5.805,25.863,30,134.689,30,151.664c0,24.813-20.187,45-45,45s-45-20.187-45-45c0-16.975,24.195-125.801,30-151.664
			V106.76c-34.243,3.105-65.497,16.663-90.507,37.522l31.254,31.254c5.859,5.859,5.859,15.352,0,21.211
			c-5.86,5.859-15.351,5.859-21.211,0l-31.254-31.254C108.422,190.503,94.865,221.757,91.76,256H136c8.291,0,15,6.709,15,15
			c0,8.291-6.709,15-15,15H76c-8.291,0-15-6.709-15-15c0-107.238,87.272-195,195-195c107.238,0,195,87.272,195,195
			C451,279.291,444.291,286,436,286z"
                  />
                </g>
              </g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
            </svg>
          }
        />

        <DrawerButton
          text="Account"
          icon={
            <svg
              version="1.1"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="45.532px"
              height="45.532px"
              viewBox="0 0 45.532 45.532"
              style={{ enableBackground: "new 0 0 45.532 45.532" }}
            >
              <g>
                <path
                  d="M22.766,0.001C10.194,0.001,0,10.193,0,22.766s10.193,22.765,22.766,22.765c12.574,0,22.766-10.192,22.766-22.765
		S35.34,0.001,22.766,0.001z M22.766,6.808c4.16,0,7.531,3.372,7.531,7.53c0,4.159-3.371,7.53-7.531,7.53
		c-4.158,0-7.529-3.371-7.529-7.53C15.237,10.18,18.608,6.808,22.766,6.808z M22.761,39.579c-4.149,0-7.949-1.511-10.88-4.012
		c-0.714-0.609-1.126-1.502-1.126-2.439c0-4.217,3.413-7.592,7.631-7.592h8.762c4.219,0,7.619,3.375,7.619,7.592
		c0,0.938-0.41,1.829-1.125,2.438C30.712,38.068,26.911,39.579,22.761,39.579z"
                />
              </g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
            </svg>
          }
          extras={[
            <DrawerButton
              text="Personal Account"
              key={0}
              isExtra
              onClick={() => history.push("/user/personaldetails")}
              icon={
                <svg
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  enableBackground="new 0 0 350 350"
                  viewBox="0 0 350 350"
                >
                  <g>
                    <path
                      d="M175,171.173c38.914,0,70.463-38.318,70.463-85.586C245.463,38.318,235.105,0,175,0s-70.465,38.318-70.465,85.587
		C104.535,132.855,136.084,171.173,175,171.173z"
                    />
                    <path d="M41.909,301.853C41.897,298.971,41.885,301.041,41.909,301.853L41.909,301.853z" />
                    <path d="M308.085,304.104C308.123,303.315,308.098,298.63,308.085,304.104L308.085,304.104z" />
                    <path
                      d="M307.935,298.397c-1.305-82.342-12.059-105.805-94.352-120.657c0,0-11.584,14.761-38.584,14.761
		s-38.586-14.761-38.586-14.761c-81.395,14.69-92.803,37.805-94.303,117.982c-0.123,6.547-0.18,6.891-0.202,6.131
		c0.005,1.424,0.011,4.058,0.011,8.651c0,0,19.592,39.496,133.08,39.496c113.486,0,133.08-39.496,133.08-39.496
		c0-2.951,0.002-5.003,0.005-6.399C308.062,304.575,308.018,303.664,307.935,298.397z"
                    />
                  </g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                </svg>
              }
            />,
            <DrawerButton
              text="Update Payment Details"
              key={1}
              isExtra
              onClick={() => history.push("/user/paymentdetails")}
              icon={
                <svg
                  height="512"
                  viewBox="0 0 512 512"
                  width="512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="Solid">
                    <path d="m200.236 93.3a163.013 163.013 0 0 1 183.4 20.359h-19.484a24 24 0 0 0 0 48h73.411a24 24 0 0 0 24-24v-73.41a24 24 0 0 0 -48 0v11.819a211.072 211.072 0 0 0 -236.026-25.061 24 24 0 1 0 22.7 42.293z" />
                    <path d="m198.9 379.83a163.01 163.01 0 0 1 -84.04-157.115 165.511 165.511 0 0 1 2.388-17.027l10.252 17.748a24 24 0 1 0 41.568-24l-36.707-63.576a24 24 0 0 0 -32.784-8.785l-63.577 36.706a24 24 0 0 0 24 41.569l9.522-5.5q-1.635 9.235-2.477 18.679a211.021 211.021 0 0 0 108.791 203.394 24 24 0 1 0 23.064-42.093z" />
                    <path d="m464 211.806h-.116a24 24 0 0 0 -23.884 24.114c.024 5.035-.188 10.143-.63 15.186a162.927 162.927 0 0 1 -108.2 139.468l9.75-16.887a24 24 0 0 0 -41.569-24l-36.651 63.477-.066.115a24 24 0 0 0 8.794 32.769l63.576 36.7a24 24 0 0 0 24-41.569l-10.264-5.926a210.9 210.9 0 0 0 138.447-179.96c.571-6.5.843-13.1.813-19.6a24 24 0 0 0 -24-23.887z" />
                  </g>
                </svg>
              }
            />,
          ]}
        />

        <DrawerButton
          text="Deposits"
          onClick={() => history.push("/user/deposit")}
          icon={
            <svg
              id="Capa_1"
              enableBackground="new 0 0 512 512"
              viewBox="0 0 512 512"
              width="512"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g>
                <path d="m0 115.913h49v40.771h-49z" />
                <path d="m0 0v85.913h49v-36.913h414v382h-414v-32h-49v113h64l8.649-32h366.702l8.649 32h64v-512z" />
                <path d="m0 328.228h49v40.772h-49z" />
                <path d="m0 186.685h49v111.543h-49z" />
                <path d="m233.954 240.85c0 12.156 9.89 22.045 22.046 22.045s22.045-9.89 22.045-22.045c0-12.156-9.89-22.046-22.045-22.046-12.156 0-22.046 9.89-22.046 22.046z" />
                <path d="m79 79v322h354v-322zm64.266 290.603h-30v-31.69h30zm112.734-12.442c-64.134 0-116.311-52.177-116.311-116.311s52.177-116.311 116.311-116.311 116.311 52.177 116.311 116.312-52.177 116.31-116.311 116.31zm142.735 12.442h-30v-31.69h30z" />
                <path d="m271.001 155.87v35.147c21.407 6.456 37.045 26.349 37.045 49.833 0 28.698-23.348 52.045-52.045 52.045-28.698 0-52.046-23.348-52.046-52.045 0-23.484 15.638-43.377 37.046-49.833v-35.147c-40.463 7.124-71.311 42.505-71.311 84.98 0 47.592 38.719 86.311 86.311 86.311s86.311-38.719 86.311-86.311c-.001-42.474-30.849-77.855-71.311-84.98z" />
              </g>
            </svg>
          }
        />

        <DrawerButton
          text="Withdrawal"
          onClick={() => history.push("/user/withdrawal")}
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              viewBox="0 0 875 875"
              width="875pt"
            >
              <g id="surface1">
                <path d="M 359.105469 125 L 232.414062 125 C 226.679688 146.996094 209.496094 164.179688 187.5 169.914062 L 187.5 298.835938 C 209.496094 304.570312 226.679688 321.753906 232.414062 343.75 L 359.105469 343.75 C 328.914062 315.4375 312.007812 275.757812 312.5 234.375 C 312.007812 192.992188 328.914062 153.3125 359.105469 125 Z M 265.625 250 L 234.375 250 L 234.375 218.75 L 265.625 218.75 Z M 265.625 250 " />
                <path d="M 642.585938 125 L 515.894531 125 C 546.085938 153.3125 562.992188 192.992188 562.5 234.375 C 562.992188 275.757812 546.085938 315.4375 515.894531 343.75 L 642.585938 343.75 C 648.320312 321.753906 665.503906 304.570312 687.5 298.835938 L 687.5 169.914062 C 665.503906 164.179688 648.320312 146.996094 642.585938 125 Z M 640.625 250 L 609.375 250 L 609.375 218.75 L 640.625 218.75 Z M 640.625 250 " />
                <path d="M 750 218.75 L 750 62.5 L 125 62.5 L 125 406.25 L 750 406.25 Z M 718.75 312.5 C 718.75 321.132812 711.757812 328.125 703.125 328.125 C 685.863281 328.125 671.875 342.113281 671.875 359.375 C 671.875 368.007812 664.882812 375 656.25 375 L 218.75 375 C 210.117188 375 203.125 368.007812 203.125 359.375 C 203.125 342.113281 189.136719 328.125 171.875 328.125 C 163.242188 328.125 156.25 321.132812 156.25 312.5 L 156.25 156.25 C 156.25 147.617188 163.242188 140.625 171.875 140.625 C 189.136719 140.625 203.125 126.636719 203.125 109.375 C 203.125 100.742188 210.117188 93.75 218.75 93.75 L 656.25 93.75 C 664.882812 93.75 671.875 100.742188 671.875 109.375 C 671.875 126.636719 685.863281 140.625 703.125 140.625 C 711.757812 140.625 718.75 147.617188 718.75 156.25 Z M 718.75 312.5 " />
                <path d="M 62.5 93.75 C 62.5 111.011719 76.488281 125 93.75 125 L 93.75 62.5 C 76.488281 62.5 62.5 76.488281 62.5 93.75 Z M 62.5 93.75 " />
                <path d="M 125 437.5 L 750 437.5 L 750 468.75 L 125 468.75 Z M 125 437.5 " />
                <path d="M 531.25 234.375 C 531.25 173.964844 489.265625 125 437.5 125 C 385.734375 125 343.75 173.964844 343.75 234.375 C 343.75 294.785156 385.734375 343.75 437.5 343.75 C 489.265625 343.75 531.25 294.785156 531.25 234.375 Z M 531.25 234.375 " />
                <path d="M 679.140625 562.5 L 640.625 562.5 L 640.625 620.265625 Z M 679.140625 562.5 " />
                <path d="M 748.835938 567.53125 C 734.65625 558.984375 716.253906 563.117188 707.078125 576.90625 L 638 680.539062 C 635.101562 684.882812 630.226562 687.5 625 687.5 L 562.5 687.5 C 502.125 687.570312 453.195312 736.5 453.125 796.875 L 421.875 796.875 C 421.96875 719.242188 484.867188 656.34375 562.5 656.25 L 609.375 656.25 L 609.375 562.5 L 546.875 562.5 L 546.875 625 L 515.625 625 L 515.625 562.5 L 453.125 562.5 L 453.125 625 L 421.875 625 L 421.875 562.5 L 359.375 562.5 L 359.375 625 L 328.125 625 L 328.125 562.5 L 265.625 562.5 L 265.625 796.875 C 265.679688 840 300.625 874.945312 343.75 875 L 538.613281 875 C 576.992188 874.875 612.523438 854.734375 632.363281 821.875 L 759.453125 610.046875 C 763.695312 603 764.953125 594.550781 762.949219 586.570312 C 760.957031 578.585938 755.867188 571.722656 748.808594 567.5 Z M 748.835938 567.53125 " />
                <path d="M 125 500 L 750 500 L 750 531.25 L 125 531.25 Z M 125 500 " />
                <path d="M 0 0 L 0 187.5 L 93.75 187.5 L 93.75 156.25 C 59.234375 156.25 31.25 128.265625 31.25 93.75 C 31.25 59.234375 59.234375 31.25 93.75 31.25 L 781.25 31.25 C 815.765625 31.25 843.75 59.234375 843.75 93.75 C 843.75 128.265625 815.765625 156.25 781.25 156.25 L 781.25 187.5 L 875 187.5 L 875 0 Z M 0 0 " />
                <path d="M 781.25 62.5 L 781.25 125 C 798.511719 125 812.5 111.011719 812.5 93.75 C 812.5 76.488281 798.511719 62.5 781.25 62.5 Z M 781.25 62.5 " />
                <path d="M 31.25 546.875 L 0 546.875 C 0.0703125 607.25 49 656.179688 109.375 656.25 L 109.375 625 C 66.25 624.945312 31.304688 590 31.25 546.875 Z M 31.25 546.875 " />
              </g>
            </svg>
          }
        />
        <DrawerButton
          text="Packages"
          icon={
            <svg
              id="Capa_1"
              enableBackground="new 0 0 512 512"
              viewBox="0 0 512 512"
              width="512"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g>
                <path d="m87.161 209.789 168.839 55.967 168.839-55.967-168.839-55.966z" />
                <path d="m466.078 227.725-187.182 62.047 45.846 72.825 187.183-62.048z" />
                <path d="m241 333.54-40.875 64.927-145.637-48.276v99.984l186.512 61.825z" />
                <path d="m311.875 398.467-40.875-64.927v178.46l186.512-61.825v-99.984z" />
                <path d="m187.258 362.597 45.846-72.825-187.182-62.047-45.847 72.824z" />
                <path d="m241 0h30v100.174h-30z" />
                <path
                  d="m122.957 31.63h30v100.174h-30z"
                  transform="matrix(.866 -.5 .5 .866 -22.376 79.926)"
                />
                <path
                  d="m323.957 66.717h100.174v30h-100.174z"
                  transform="matrix(.5 -.866 .866 .5 116.252 364.788)"
                />
              </g>
            </svg>
          }
          extras={[
            <DrawerButton
              text="Activate A Package"
              key={0}
              isExtra
              onClick={() => history.push("/user/activatepackage")}
              icon={
                <svg
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 512 512"
                  enableBackground="new 0 0 512 512"
                >
                  <g>
                    <g>
                      <path
                        d="M500.6,212.6l-59.9-14.7c-3.3-10.5-7.5-20.7-12.6-30.6l30.6-51c3.6-6,2.7-13.5-2.1-18.3L414,55.4
			c-4.8-4.8-12.3-5.7-18.3-2.1l-51,30.6c-9.9-5.1-20.1-9.3-30.6-12.6l-14.4-59.9C297.9,4.8,291.9,0,285,0h-60
			c-6.9,0-12.9,4.8-14.7,11.4l-14.4,59.9c-10.5,3.3-20.7,7.5-30.6,12.6l-51-30.6c-6-3.6-13.5-2.7-18.3,2.1L53.4,98
			c-4.8,4.8-5.7,12.3-2.1,18.3l30.6,51c-5.1,9.9-9.3,20.1-12.6,30.6l-57.9,14.7C4.8,214.1,0,220.1,0,227v60
			c0,6.9,4.8,12.9,11.4,14.4l57.9,14.7c3.3,10.5,7.5,20.7,12.6,30.6l-30.6,51c-3.6,6-2.7,13.5,2.1,18.3L96,458.6
			c4.8,4.8,12.3,5.7,18.3,2.1l51-30.6c9.9,5.1,20.1,9.3,30.6,12.6l14.4,57.9c1.8,6.6,7.8,11.4,14.7,11.4h60
			c6.9,0,12.9-4.8,14.7-11.4l14.4-57.9c10.5-3.3,20.7-7.5,30.6-12.6l51,30.6c6,3.6,13.5,2.7,18.3-2.1l42.6-42.6
			c4.8-4.8,5.7-12.3,2.1-18.3l-30.6-51c5.1-9.9,9.3-20.1,12.6-30.6l59.9-14.7c6.6-1.5,11.4-7.5,11.4-14.4v-60
			C512,220.1,507.2,214.1,500.6,212.6z M255,332c-41.4,0-75-33.6-75-75c0-41.4,33.6-75,75-75c41.4,0,75,33.6,75,75
			C330,298.4,296.4,332,255,332z"
                      />
                    </g>
                  </g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                </svg>
              }
            />,
            <DrawerButton
              text="Current Package"
              key={1}
              isExtra
              onClick={() => history.push("/user/currentpackage")}
              icon={
                <svg
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 148.961 148.961"
                  enableBackground="new 0 0 148.961 148.961"
                >
                  <g>
                    <path
                      d="M146.764,17.379c-2.93-2.93-7.679-2.929-10.606,0.001L68.852,84.697L37.847,53.691c-2.93-2.929-7.679-2.93-10.606-0.001
		c-2.93,2.929-2.93,7.678-0.001,10.606l36.309,36.311c1.407,1.407,3.314,2.197,5.304,2.197c1.989,0,3.897-0.79,5.304-2.197
		l72.609-72.622C149.693,25.057,149.693,20.308,146.764,17.379z"
                    />
                    <path
                      d="M130.57,65.445c-4.142,0-7.5,3.357-7.5,7.5v55.57H15V20.445h85.57c4.143,0,7.5-3.357,7.5-7.5c0-4.142-3.357-7.5-7.5-7.5
		H7.5c-4.142,0-7.5,3.357-7.5,7.5v123.07c0,4.143,3.358,7.5,7.5,7.5h123.07c4.143,0,7.5-3.357,7.5-7.5v-63.07
		C138.07,68.803,134.713,65.445,130.57,65.445z"
                    />
                  </g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                  <g></g>
                </svg>
              }
            />,
            <DrawerButton
              text="Transaction ROI"
              key={2}
              isExtra
              onClick={() => history.push("/user/transaction")}
              icon={
                <svg
                  id="bold_1_"
                  enableBackground="new 0 0 24 24"
                  height="512"
                  viewBox="0 0 24 24"
                  width="512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="m3 11c-.256 0-.512-.098-.707-.293-.391-.391-.391-1.023 0-1.414l6.906-6.906c.391-.391 1.023-.391 1.414 0s.391 1.023 0 1.414l-6.906 6.906c-.195.195-.451.293-.707.293z" />
                  <path d="m11.25 6c-.195 0-.387-.076-.53-.22l-3.5-3.5c-.215-.214-.279-.537-.163-.817s.39-.463.693-.463h3.5c.414 0 .75.336.75.75v3.5c0 .303-.183.577-.463.693-.093.038-.19.057-.287.057z" />
                  <path d="m7 15.25v5.75h-6v-5.75c0-.69.56-1.25 1.25-1.25h3.5c.69 0 1.25.56 1.25 1.25z" />
                  <path d="m15 11.25v9.75h-6v-9.75c0-.69.56-1.25 1.25-1.25h3.5c.69 0 1.25.56 1.25 1.25z" />
                  <path d="m23 7.25v13.75h-6v-13.75c0-.69.56-1.25 1.25-1.25h3.5c.69 0 1.25.56 1.25 1.25z" />
                  <path d="m23.25 24h-22.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h22.5c.414 0 .75.336.75.75s-.336.75-.75.75z" />
                </svg>
              }
            />,
          ]}
        />
        <DrawerButton
          text="Referrals"
          onClick={() => history.push("/user/referrals")}
          icon={
            <svg
              id="Layer_3"
              enableBackground="new 0 0 64 64"
              viewBox="0 0 64 64"
              width="512"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="m42.519 49 3.141 3.926-3.046 3.046-1.387-6.933c.256-.022.512-.039.773-.039zm6.4 0-1.919 2.399-1.919-2.399zm2.562 0h.519c.261 0 .517.017.772.039l-1.387 6.933-3.046-3.046zm4.519-8h-.059c.037-.329.059-.662.059-1v-3c1.103 0 2 .897 2 2s-.897 2-2 2zm-18-10c0-3.309 2.691-6 6-6h6c3.309 0 6 2.691 6 6v4h-1-3c-1.103 0-2-.897-2-2v-3-1h-2v1c0 2.757-2.243 5-5 5h-4-1zm0 10c-1.103 0-2-.897-2-2s.897-2 2-2v3c0 .338.022.671.059 1zm2-1v-3h3c2.124 0 4.027-.955 5.312-2.454.606 1.44 2.03 2.454 3.688 2.454h2v3c0 3.859-3.14 7-7 7s-7-3.141-7-7zm21 21h-28v-3c0-4.009 2.637-7.412 6.266-8.572l1.754 8.769c.072.361.338.654.691.761.095.028.192.042.289.042.261 0 .517-.103.707-.293l4.293-4.293 4.293 4.293c.19.19.446.293.707.293.097 0 .194-.014.29-.043.353-.106.619-.399.691-.761l1.754-8.769c3.628 1.161 6.265 4.564 6.265 8.573z" />
              <path d="m12.519 27 3.141 3.926-3.046 3.046-1.387-6.933c.256-.022.512-.039.773-.039zm6.4 0-1.919 2.399-1.919-2.399zm2.562 0h.519c.261 0 .517.017.772.039l-1.387 6.933-3.046-3.046zm4.519-8h-.059c.037-.329.059-.662.059-1v-3c1.103 0 2 .897 2 2s-.897 2-2 2zm-18-10c0-3.309 2.691-6 6-6h6c3.309 0 6 2.691 6 6v4h-1-3c-1.103 0-2-.897-2-2v-3-1h-2v1c0 2.757-2.243 5-5 5h-4-1zm0 10c-1.103 0-2-.897-2-2s.897-2 2-2v3c0 .338.022.671.059 1zm2-1v-3h3c2.124 0 4.027-.955 5.312-2.454.606 1.44 2.03 2.454 3.688 2.454h2v3c0 3.859-3.14 7-7 7s-7-3.141-7-7zm21 21h-28v-3c0-4.009 2.637-7.412 6.266-8.572l1.754 8.769c.072.361.338.654.691.761.095.028.192.042.289.042.261 0 .517-.103.707-.293l4.293-4.293 4.293 4.293c.19.19.446.293.707.293.097 0 .194-.014.29-.043.353-.106.619-.399.691-.761l1.754-8.769c3.628 1.161 6.265 4.564 6.265 8.573z" />
              <path d="m13 51c0-.553-.448-1-1-1h-1.586l4.586-4.586 4.586 4.586h-1.586c-.552 0-1 .447-1 1 0 4.155 2.123 7.824 5.341 9.979-5.208-.341-9.341-4.686-9.341-9.979z" />
            </svg>
          }
        />
      </div>
      <DrawerButton
        last
        text="Logout"
        onClick={handleLogout}
        icon={
          <svg
            id="bold"
            enableBackground="new 0 0 24 24"
            height="512"
            viewBox="0 0 24 24"
            width="512"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g>
              <path d="m15 13c-.553 0-1 .448-1 1v4c0 .551-.448 1-1 1h-3v-15c0-.854-.544-1.617-1.362-1.901l-.296-.099h4.658c.552 0 1 .449 1 1v3c0 .552.447 1 1 1s1-.448 1-1v-3c0-1.654-1.346-3-3-3h-10.75c-.038 0-.07.017-.107.022-.048-.004-.094-.022-.143-.022-1.103 0-2 .897-2 2v18c0 .854.544 1.617 1.362 1.901l6.018 2.006c.204.063.407.093.62.093 1.103 0 2-.897 2-2v-1h3c1.654 0 3-1.346 3-3v-4c0-.552-.447-1-1-1z" />
              <path d="m23.707 9.293-4-4c-.286-.286-.716-.372-1.09-.217-.373.155-.617.52-.617.924v3h-4c-.552 0-1 .448-1 1s.448 1 1 1h4v3c0 .404.244.769.617.924.374.155.804.069 1.09-.217l4-4c.391-.391.391-1.023 0-1.414z" />
            </g>
          </svg>
        }
      />
    </DrawerContainer>
  );
};

const mapStateToProps = (state) => ({
  userToken: state.user.userToken,
});

const mapDispatchToProps = (dispatch) => ({
  logUserOut: () => dispatch(logUserOut()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Drawer);

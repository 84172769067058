import React from "react";
import { WeeklyPayoutContainer } from "./weeklyPayout.style";
import YellowCircle from "../../../components/yellowCircle/YellowCircle";
import Table from "../../../components/table/table.component";

const WeeklyPayout = () => {
  const yellowCircle = [
    { key: 1 },
    { key: 2 },
    { key: 3 },
    { key: 4 },
    { key: 5 },
    { key: 6 },
    { key: 7 },
  ];

  return (
    <WeeklyPayoutContainer>
      <div className="yellowCircles">
        {yellowCircle.map((circle) => (
          <YellowCircle size="0.7rem" marginRight="0.4rem" key={circle.key} />
        ))}
      </div>
      <div className="heading">
        <h3>Weekly Payouts</h3>
        <YellowCircle size="0.4rem" marginRight="0.2rem" />
      </div>
      <p>Top Paid Investors</p>
      <div className="tableContainer">
        <Table />
      </div>
    </WeeklyPayoutContainer>
  );
};

export default WeeklyPayout;

import styled from "styled-components";
import HomeBackground from "../../assets/background-bg.png"

export const HomePageContainer = styled("div")`
  background-image:url(${HomeBackground});
  background-repeat:repeat;
  width:100%;
  margin:0;
  padding:5rem 4rem 0 4rem;
  display:flex;
  flex-direction:column;
  justify-content:space-between;
 align-items:center;

 .YellowCircleContainer{
    position:fixed;
    bottom:-11vh;
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    align-items:flex-end;
    transition:0.4s;
 }
  .YellowCircleContainer:hover{
    bottom:-9vh;
    transition:0.4s;
 }
}

   @media screen and (max-width: 1000px) {
 .YellowCircleContainer{
    bottom:-10vh;
 }
  .YellowCircleContainer:hover{
    bottom:-8vh;
    .yellowCircle{
       border:2px solid red;
    }
 }
   }
   @media screen and (max-width: 500px) {
padding:5rem 1rem 0 1rem;
   }
`;
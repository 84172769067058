import React from "react";
import { RegisteredUsersContainer } from "./registeredUsers.styles";
import UpwardCounter from "../../../components/upwardCounter/upwardCounter.component";

const RegisteredUsers = () => {
  return (
    <RegisteredUsersContainer>
      <div className="heading">
        <UpwardCounter fontSize="4rem" finalNumber={31400} />
        <p>+</p>
      </div>
      <h3>Registered Users</h3>
      <p>
        Earn instant top-up everyday on your initial cryptocurrency Investment
        with a minimum of 2.50% daily profit return and earn 200% of your
        investment after each investment cycle.
      </p>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        onClick={() => window.open("/user/dashboard", "_blank").focus()}
        target="_blank"
        rel="noopener noreferrer"
      >
        <button className="button">Get Started</button>
      </a>
    </RegisteredUsersContainer>
  );
};

export default RegisteredUsers;

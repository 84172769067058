import styled from "styled-components";

export const WhatInvestorSayContainer = styled("div")`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content:space-around;
  align-items: center;
  margin-bottom:30vh;
  h1{
    color: white;
    font-size: 4.2rem;
    font-weight: thin;
    margin: 0;4;
    padding: 3rem 0;
    text-align: center;
  }
  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    z-index:-1;

    h2 {
      margin: 1.5rem 0 0 0;
      font-size: 1.5rem;
      color: #ffffff;
    }
    p {
      font-size: 1.2rem;
      margin: 0.6rem 0;
      color: #ffffff;
    }
  }
  
  @media screen and (max-width: 900px) {
 h1 {
      font-size: 2.5rem;
      padding:0.5rem;
    }
  }
  @media screen and (max-width: 600px) {
    justify-content: flex-start;
    align-items: center;

    h1 {
      font-size: 2.5rem;
      padding:0.5rem;
    }
    .content {
      align-items: center;

      h2 {
        margin: 1.3rem 0 0 0;
        font-size: 1.3rem;
      }
      p {
        text-align: center;
      }
    }
  }
`;

import React from "react";
import { AboutUsSectionContainer } from "./aboutUsSection.styles.jsx";
import EmployeeImage from "../../../assets/employee1.png";
import ReactPlayer from "react-player";
import YellowCircle from "../../../components/yellowCircle/YellowCircle.jsx";

const AboutUsSection = () => {
  return (
    <AboutUsSectionContainer id="aboutUs">
      <h2 className="whoWeAre">Who are we ?</h2>
      <div className="contentWrapper">
        <div className="textSection">
          <div className="h1Wrappper">
            <h1>About Us Stripe Option</h1>
            <span>
              <YellowCircle size={"0.5rem"} marginLeft={"0.3rem"} />
            </span>
          </div>
          <p className="paragraph">
            We provide a unique global perspective through our global network,
            in-depth market and sector knowledge. This puts us in an excellent
            position to address the increasingly global needs of our clients.
          </p>

          <p className="paragraph">
            Our work is always intended to provide a clear benefit to all our
            clients in both the short and long term investments. We focus our
            resources, leverage our global scale, and drive excellence in all we
            do while enhancing our partner-like culture to ensure profits on all
            sectors of our company.
          </p>
          <div className="videoWrapper">
            {<ReactPlayer /> ? (
              <ReactPlayer
                url="https://www.youtube.com/watch?v=41JCpzvnn_0&feature=youtu.be"
                height="100%"
                width="100%"
              />
            ) : (
              <p>Unable to load Video</p>
            )}
          </div>
        </div>
        <div className="imageSection">
          <img src={EmployeeImage} alt="employee" />
        </div>
      </div>
    </AboutUsSectionContainer>
  );
};

export default AboutUsSection;

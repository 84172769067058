import React from "react";
import { FooterContainer } from "./footer.styles";
import Logo from "./../../../assets/Stripe Option Normal.png";

const Footer = () => {
  return (
    <FooterContainer>
      <div className="container">
        <img src={Logo} alt="Company Logo" />

        <p>
          Founded in the year 2015, Stripe Option is one of the world’s leading
          independent branded companies. Headquartered in United Kingdom.
        </p>
        <p>
          We have offices in five countries. We provide a unique global
          perspective through our global network, in-depth market and sector
          knowledge.
        </p>

        <p className="bottom">&copy; All Rights Reserved 2020</p>
        <p className="bottom">Stripe Option</p>
      </div>
    </FooterContainer>
  );
};

export default Footer;

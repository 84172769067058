import styled from "styled-components";

export const OurImpactContainer = styled("div")`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 40vh;

  .yellowCircles{
display:flex;
justify-content:space-around;
align-items:center;
  }
  .heading{
display:flex;
justify-content flex-start;
align-items:baseline;
  h3{
 font-size:2.2rem;
 font-weight:thin;
 margin:1.4rem 0;
 padding:0 0.6rem 0 0;
 background: -webkit-linear-gradient(20deg,#ffbb3e, #ffffff 60%);-webkit-background-clip: text;
 -webkit-text-fill-color: transparent;
 
  }
}
  
  p{
font-size:1.5rem;
margin:0.5rem 0;
color:white;
  }
  .analyticsWrapper{
    width:100%;
    height:30vh;
    margin-top:10vh;
display:flex;
justify-content:space-between;
align-items:center;
flex-wrap:wrap;

.analyticContainer{
  margin:0.5rem;
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
    .analytics{
      display:flex;
      flex-direction: column;
  justify-content: space-between;
  align-items: center;
       p {
        color: #ffffff;
        margin: 0;
        padding: 0;
        font-size: 1.5rem;
        font-weight: bold;
        text-align:center;
      }
      .bottomLine {
        width: 50%;
        height: 6px;
         margin-top: 0.3rem;
         background: #ffbb3e;
         border-radius:1rem;
      }
    }
    }

    .analyticContainer:hover {
      .analytics{
      .bottomLine {
        width: 100%;
        transition: 0.6s;
      }
    }
  }
}

  @media screen and (max-width: 930px) {
  margin-bottom: 20vh;
 p{
font-size:1.4rem;
text-align:center;
  }
  .analyticsWrapper{
    flex-direction:row;
flex-wrap:wrap;

height:auto;

.analyticContainer{
  min-width:40%;
  height:auto;
  margin-bottom:2rem;
  p{
    font-size:1rem;
    text-align:center;
  }
  .bottomLine {
        height:3px;
      }
    }
    }
  }
   @media screen and (max-width: 755px) {
.analyticsWrapper{
flex-direction:column;
align-items:center;
height:auto;
.analyticContainer{
  width:100%;
  height:auto;
  margin:0 0 2rem 0;
      .bottomLine {
         margin-top: 0.5rem;
      }
    }
    }
}
   }
  
  @media screen and (max-width: 500px) {
  margin-bottom: 20vh;
  .analyticsWrapper{
flex-direction:column;
height:auto;
.analyticContainer{
  width:100%;
  height:auto;
  margin-bottom:2rem;
      .bottomLine {
         margin-top: 0.5rem;
      }
    }
    }
}
  }
`;
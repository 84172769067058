import React, { useEffect, useRef } from "react";
import { HomePageContainer } from "./homePage.styles.jsx";
/*import ScrollingPrices from "../../components/scrollingPrices/scrollingPrices.component";*/
import HeroSection from "./heroSection/heroSection.component";
import AboutUsSection from "./aboutUsSection/aboutUsSection";
import OurImpact from "./ourImpact/ourImpact.component";
import InvestmentPlan from "./investmentPlanSection/investmentPlan.component";
import WhatInvestorSay from "./whatInvestorSay/whatInvestorSay.component";
import RegisteredUsers from "./registeredUsers/registeredUsers.component";
import ContactUs from "./contactUs/contactUs.component";
import Footer from "./footer/footer.component";
import Header from "../../components/header/header.component";
import YellowCircle from "../../components/yellowCircle/YellowCircle";
import WeeklyPayout from "./weeklyPayout/weeklyPayout.component";
import HomePageModal from "../../components/homePageModal/homePageModal.component";
import { Link } from "react-scroll";
const HomePage = () => {
  const instance = useRef(null);

  useEffect(() => {
    const tawk = document.createElement("script");
    <script src="//code.tidio.co/p4o28t382ibaywifkegjrfioehbxqfos.js" async></script>
    //script.async = true;
    //var Tawk_API = Tawk_API || {},
    //Tawk_LoadStart = new Date();
//     tawk.type = "text/javascript";
//     tawk.innerHTML = `
// //        (function(){
// // var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
// // s1.async=true;
// // s1.src='https://embed.tawk.to/5fd527eba8a254155ab2ba9a/default';
// // s1.charset='UTF-8';
// // s1.setAttribute('crossorigin','*');
// // s0.parentNode.insertBefore(s1,s0);
// // })();
//     `;

    instance.current.appendChild(tawk);
    const getTokenScript = document.createElement("script");
    //script.async = true;
    getTokenScript.type = "text/javascript";
    getTokenScript.innerHTML = `
    (function () {
      var options = {
        email: "support@stripeoption.co", // Email
        call_to_action: "Message us", // Call to action
        position: "left", // Position may be 'right' or 'left'
      };
      var proto = document.location.protocol,
        host = "getbutton.io",
        url = proto + "//static." + host;
      var s = document.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = url + '/widget-send-button/js/init.js';
      s.onload = function () {
        WhWidgetSendButton.init(host, proto, options);
      };
      var x = document.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s, x);
    })();
    `;
    instance.current.appendChild(getTokenScript);
  }, []);

  return (
    <HomePageContainer>
      <Header ScrollLink={Link} />
      <HomePageModal
        header="stripeoption.co says"
        paragraph="NoTE: All deposits should be paid directly to the company's crypto wallet address. No payment should be made to any account manager as the company would not be held responsible for any loss of CLIENT's capital if not paid to the company's wallet address"
      />
      {/* <ScrollingPrices /> */}
      <HeroSection />
      <AboutUsSection />
      <OurImpact />
      <InvestmentPlan />
      <WhatInvestorSay />
      <RegisteredUsers />
      <WeeklyPayout />
      <ContactUs />
      <Footer />
      <div className="YellowCircleContainer">
        <YellowCircle size="1rem" />
        <YellowCircle size="8rem" />
      </div>
      <div ref={instance}></div>
    </HomePageContainer>
  );
};

export default HomePage;

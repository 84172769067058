import React, { useState } from "react";
import { Button } from "./drawer.styles";
import { SlideDown } from "react-slidedown";
import "react-slidedown/lib/slidedown.css";

const DrawerButton = ({ text, icon, extras, last, isExtra, onClick }) => {
  const [showExtra, toggleShowExtra] = useState(false);

  const handleClick = () => {
    toggleShowExtra(!showExtra);
  };
  return (
    <div>
      <Button
        isExtra={isExtra}
        onClick={extras ? handleClick : onClick}
        last={last}
      >
        <div>
          {icon}
          <span>{text}</span>
          {extras && extras[0] ? (
            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 491.996 491.996"
              enableBackground="new 0 0 491.996 491.996"
              className="arr"
            >
              <g>
                <g>
                  <path
                    d="M484.132,124.986l-16.116-16.228c-5.072-5.068-11.82-7.86-19.032-7.86c-7.208,0-13.964,2.792-19.036,7.86l-183.84,183.848
			L62.056,108.554c-5.064-5.068-11.82-7.856-19.028-7.856s-13.968,2.788-19.036,7.856l-16.12,16.128
			c-10.496,10.488-10.496,27.572,0,38.06l219.136,219.924c5.064,5.064,11.812,8.632,19.084,8.632h0.084
			c7.212,0,13.96-3.572,19.024-8.632l218.932-219.328c5.072-5.064,7.856-12.016,7.864-19.224
			C491.996,136.902,489.204,130.046,484.132,124.986z"
                  />
                </g>
              </g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
            </svg>
          ) : (
            <></>
          )}
        </div>
      </Button>
      <SlideDown className={"my-dropdown-slidedown"}>
        {showExtra && extras ? <div className="extras"> {extras} </div> : <></>}
      </SlideDown>
    </div>
  );
};

export default DrawerButton;

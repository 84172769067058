import React from "react";
import { HeroSectionContainer } from "./heroSection.styles";
import YellowCircle from "../../../components/yellowCircle/YellowCircle";

const HeroSection = () => {
  return (
    <HeroSectionContainer>
      <div className="h1Wrapper" id="heroSection">
        <h1>Stripe Option</h1>
        <YellowCircle size={"1rem"} />
      </div>
      <p>Take the right steps in gaining financial freedom</p>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        onClick={() => window.open("/user/dashboard", "_blank").focus()}
        target="_blank"
        rel="noopener noreferrer"
      >
        Get Started
      </a>
    </HeroSectionContainer>
  );
};

export default HeroSection;
